import { generateFormField } from "elements/Form/services/form"

const AuthForm = ({ module, formFields }) => {
  return formFields
    .filter((formField) => {
      return formField.inclusions.includes(module)
    })
    .map((formField) => {
      if (formField?.required === true) formField.isRequired = true

      if (!formField?.referenceAnswer) {
        return generateFormField({
          formField,
          formFields,
        })
      }
      return null
    })
}

export default AuthForm
