import { useStaticQuery, graphql } from "gatsby"

import { flattenNode } from "services/arrays"

const ALL_AIRTABLE_FIELDS_QUERY = graphql`
  query {
    allAirtableallWebsiteFormFields(
      filter: { data: { Module: { glob: "Enrollment*" } } }
    ) {
      nodes {
        data {
          Name
          Field_Names
          Required
        }
      }
    }
  }
`

export const useProfileFormFields = () => {
  const data = useStaticQuery(ALL_AIRTABLE_FIELDS_QUERY)

  let formFields = flattenNode(data.allAirtableallWebsiteFormFields)
  return formFields
}
