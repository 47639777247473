import React, { useState, useEffect, useContext } from "react"
import { Link, navigate } from "gatsby"
import { Formik, Form } from "formik"
import classNames from "classnames"

import AuthForm from "./AuthForm"
import Layout from "layout"
import Button from "elements/Button"
import Message from "elements/Message"
import Container from "layout/Container"

import Card from "elements/Card"

import { authValidationSchema } from "./utils/authValidationSchema"
import { AppContext } from "context/AppContext"
import { isBrowser } from "services/general"
import { handleAuthSubmit } from "./services/auth"
import { hasSignedInUser } from "./services/user"

import { useProfileFormFields } from "../Profile/hooks/useProfileFormFields"

const Auth = (props) => {
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState({})
  const { state, dispatch } = useContext(AppContext)

  let { pageContext, location } = props
  let { module } = pageContext

  let includedPages = pageContext?.includedPages || []
  let includedPaths = includedPages.map((page) => page.path)
  let hasSignUp = includedPaths?.includes("sign-up")

  let profileFormFields = useProfileFormFields()

  const handleSubmit = (values, { resetForm }) => {
    setMessage({})
    setLoading(true)

    let payload = {
      values,
      resetForm,
      setMessage,
      setLoading,
      module: module.name,
      profileFormFields,
      pageContext,
    }
    handleAuthSubmit({
      payload,
      state,
      dispatch,
      location,
    })
  }

  useEffect(() => {
    if (isBrowser()) {
      if (module.name !== "access-code" && hasSignedInUser()) navigate("/")

      if (!state.auth.hasBeenVerified && module.name === "sign-up")
        navigate("/verify-email")
      if (state.auth.hasBeenVerified && module.name === "sign-in") {
        setMessage({
          type: "danger",
          content:
            "This email is already a registered MedGrocer account. Please sign in using these credentials.",
        })

        dispatch({ type: "RESET_DETAILS" })
      }
    }
  }, [state.auth.hasBeenVerified, module.name, dispatch])

  return (
    <Layout
      background="light-teal"
      seoTitle={module.seoTitle}
      display={{ footer: true, helpCenterBanner: false }}
    >
      <Container isCentered tablet={8} desktop={6} fullhd={4}>
        <Card
          className="my-3"
          title={module?.title}
          subtitle={module?.subtitle}
        >
          <Formik
            initialValues={state.auth}
            validationSchema={authValidationSchema(module.name)}
            onSubmit={handleSubmit}
          >
            {({ values }) => (
              <Form>
                <AuthForm
                  module={module.name}
                  formFields={pageContext.formFields}
                />
                {module.name === "sign-in" && (
                  <p className="is-size-6 pb-1 pt-0">
                    <Link to="/forgot-password">Forgot password</Link>
                  </p>
                )}
                {message?.content && (
                  <Message color={message?.type}>
                    {message?.content?.message || message?.content}
                  </Message>
                )}
                <Button
                  type="submit"
                  color="primary"
                  disabled={loading}
                  className={classNames({ "is-loading": loading })}
                  isFullwidth
                >
                  {module.cta}
                </Button>
                {hasSignUp && module.name === "sign-in" && (
                  <section>
                    <p className="has-text-centered is-size-6 pt-1">
                      Don't have an account yet?{" "}
                      <Link to="/verify-email">Enroll Now.</Link>
                    </p>
                  </section>
                )}
                {module.name !== "sign-in" && module.name !== "access-code" && (
                  <section>
                    <p className="has-text-centered is-size-7 pt-1">
                      By signing up, you agree to our{" "}
                      <Link to={`/terms-and-conditions`}>
                        Terms and Conditions
                      </Link>{" "}
                      and <Link to={`/privacy-policy`}>Privacy Policy</Link>
                    </p>
                    <p className="has-text-centered is-size-6 mt-2">
                      Already have an account?{" "}
                      <Link to="/sign-in">Sign In.</Link>
                    </p>
                  </section>
                )}
              </Form>
            )}
          </Formik>
        </Card>
      </Container>
    </Layout>
  )
}

export default Auth
